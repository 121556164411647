<template>
  <DetailTemplate
    :customClass="'lead-detail detail-page'"
    v-if="getPermission('lead:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ leadTitle }}
          </h1>
          <v-chip
            v-if="leadDetail.status_relation"
            label
            class="custom-status font-weight-600 custom-grey-border text-uppercase mx-2"
            :color="leadDetail.status_relation.color"
            :text-color="leadDetail.status_relation.textcolor"
          >
          {{ leadDetail.status_relation.text }} 
        </v-chip>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="!leadDetail.prospect">
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="red lighted-1"
          v-on:click="updateMoreAction('delete')"
        >
          Delete <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-if="moreActions.length > 1">
        <v-menu
          transition="slide-y-transition"
          bottom
          left
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <template v-if="more.action == 'edit'">
                <v-list-item
                  v-if="getPermission('lead:update')"
                  link
                  v-on:click="updateMoreAction(more.action)"
                  :key="index"
                  :disabled="more.disabled"
                >
                  <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                    <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="font-weight-500 font-size-14">{{
                    more.title
                  }}</v-list-item-title>
                </v-list-item>
              </template>
              <v-list-item
                v-else
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        v-else-if="getPermission('lead:update')"
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        color="cyan white--text"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="updateMoreAction('edit')"
      >
        <v-icon small left>mdi-pencil</v-icon>
        Edit
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="leadTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent tab-sticky"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <v-container fluid class="pa-0">
        <v-tabs-items v-model="leadTab">
          <v-tab-item v-if="getPermission('lead:view')" value="lead">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col md="12" class="py-0">
                  <p class="middle-header-background px-4 mb-0 font-size-20">
                    Basic Details
                  </p>
                </v-col>
                <v-col md="12" class="py-4 px-8">
                  <table width="100%" style="table-layout: fixed">
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        First Name
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="first_name"
                          label="first name"
                        ></ShowValue>
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">Source</td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="source.text"
                          label="source"
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Customer Phone No.
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="phone_number"
                          label="customer phone no."
                        ></ShowValue>
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Lead Value
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        {{ formatCompactNumber(leadDetail.lead_value) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        (Alternate) Phone No.
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="alt_phone_number"
                          label="(alternate) phone no."
                        ></ShowValue>
                      </td>
                      <template v-if="leadDetail.prospect">
                        <td class="pb-2 font-size-18 text-capitalize">
                          Prospect #
                        </td>
                        <td
                          class="pb-2 font-size-18 font-weight-600 text-capitalize"
                        >
                          <Barcode
                            dense
                            :barcode="leadDetail.prospect_relation.barcode"
                            route="prospect.detail"
                            :id="leadDetail.prospect_relation.id"
                          ></Barcode>
                        </td>
                      </template>
                    </tr>
                  </table>
                </v-col>
                <v-col md="12" class="py-0">
                  <p class="middle-header-background px-4 mb-0 font-size-20">
                    Other Details
                  </p>
                </v-col>
                <v-col md="12" class="py-4 px-8">
                  <table width="100%" style="table-layout: fixed">
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Lead Subject
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="subject"
                          label="lead subject"
                        ></ShowValue>
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Lead Number
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="barcode"
                          label="lead number"
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Last Name
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="last_name"
                          label="last name"
                        ></ShowValue>
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Reference #
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="reference"
                          label="reference #"
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Customer Company
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="company"
                          label="customer company"
                        ></ShowValue>
                      </td>
                      <td
                        v-if="false"
                        class="pb-2 font-size-18 text-capitalize"
                      >
                        NRIC Number
                      </td>
                      <td
                        v-if="false"
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="nric_no"
                          label="nric number"
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Customer Email
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-lowercase"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="email"
                          label="customer email"
                        ></ShowValue>
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">Block</td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="unit_no"
                          label="block"
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Customer Website
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="website"
                          label="customer website"
                        ></ShowValue>
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">Street</td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="address_1"
                          label="street"
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Lead Date
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-lowercase"
                      >
                        {{ formatDate(leadDetail.lead_date) }}
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Unit No.
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="address_2"
                          label="unit no."
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Last Contacted Date
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        {{ formatDate(leadDetail.last_contact) }}
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Postal Code
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="postal_code"
                          label="postal code"
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">Status</td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="status_relation.text"
                          label="status"
                        ></ShowValue>
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">Country</td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="country"
                          label="Country"
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Assigned
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="assigned_user.display_name"
                          label="assigned"
                        ></ShowValue>
                      </td>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Category
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="category.text"
                          label="category"
                        ></ShowValue>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2 font-size-18 text-capitalize">
                        Description
                      </td>
                      <td
                        colspan="3"
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <ShowValue
                          :object="leadDetail"
                          object-key="description"
                          label="description"
                        ></ShowValue>
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('attachment:view')"
            value="attachment"
          >
            <v-container fluid class="pa-0">
              <v-layout>
                <v-flex>
                  <v-btn
                    v-if="!attachmentActive"
                    v-on:click="addAttachment"
                    class="my-2 mr-2 custom-bold-button white--text"
                    color="cyan"
                    small
                  >
                    Add Attachment
                  </v-btn>
                  <v-btn
                    v-if="attachmentActive"
                    class="my-2 ml-2 custom-bold-button white--text"
                    color="cyan"
                    small
                    v-on:click="saveAttachment"
                  >
                    Save Attachment
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-row>
                <v-col md="12" v-if="attachmentActive">
                  <AttachmentWithName
                    v-on:file:updated="updateAttachment"
                    :label="true"
                    :isMultiple="true"
                    allowUpload
                  ></AttachmentWithName>
                </v-col>
                <v-col md="12" v-if="attachments.length > 0">
                  <AttachmentView
                    :attachments="attachments"
                    type="lead"
                  ></AttachmentView>
                </v-col>
                <v-col md="12" v-else>
                  <template v-if="!pageLoading">
                    <p
                      class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mx-2"
                      />
                      Uhh... There are no attachment for this lead at the
                      moment.
                    </p>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item value="meeting">
            <Meeting
              type="lead"
              :type-id="lead"
              :customer-name="leadDetail.name"
            ></Meeting>
          </v-tab-item>
          <v-tab-item v-if="getPermission('proposal:view')" value="proposal">
            <Proposal :detail.sync="leadDetail" type="lead"></Proposal>
          </v-tab-item>
          <v-tab-item v-if="getPermission('task:view')" value="task">
            <Tasks :detail.sync="leadDetail" type="lead"></Tasks>
          </v-tab-item>
          <v-tab-item value="comment">
            <v-container fluid>
              <v-layout>
                <v-flex>
                  <v-btn
                    v-on:click="addNote"
                    class="my-2 mr-2 custom-bold-button white--text"
                    color="cyan"
                    small
                    :disabled="commentLoading"
                  >
                    <template v-if="commentActive">Close</template>
                    <template v-else>Add Note</template>
                  </v-btn>
                  <v-btn
                    v-if="commentActive"
                    class="my-2 ml-2 custom-bold-button white--text"
                    color="cyan"
                    small
                    :disabled="commentLoading"
                    v-on:click="saveNote"
                  >
                    Save Note
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-row>
                <template v-if="commentActive">
                  <v-col md="12" class="px-8">
                    <editor v-model="commentCreate.description" />
                  </v-col>
                  <v-col md="12">
                    <AttachmentWithName
                      v-on:file:updated="updateNoteAttachment"
                      :label="true"
                      :attachments.sync="notesAttachments"
                      allowUpload
                    ></AttachmentWithName>
                  </v-col>
                </template>
                <v-col md="12" v-if="comments.length > 0">
                  <v-timeline dense clipped>
                    <v-timeline-item
                      color="white"
                      v-for="(row, index) in comments"
                      :key="index"
                      large
                    >
                      <template v-slot:icon>
                        <v-avatar>
                          <v-img
                            contain
                            :src="row.profile_logo"
                            :lazy-src="$defaultProfileImage"
                          ></v-img>
                        </v-avatar>
                      </template>
                      <v-row class="pt-1">
                        <v-col md="3">
                          <em
                            v-tippy="{ arrow: true }"
                            content="Created At"
                            class="font-weight-600"
                            >{{ formatDateTime(row.added_at) }}</em
                          ><br />
                          <em
                            v-tippy="{ arrow: true }"
                            content="Created By"
                            class="font-weight-600 text-capitalize"
                            >{{ row.full_name }}</em
                          >
                        </v-col>
                        <v-col md="9">
                          <div class="task-comment-delete">
                            <v-col col="12" class="pr-5">
                              <v-btn
                                icon
                                :disabled="isPageLoading"
                                v-on:click="deleteNote(row.id)"
                              >
                                <v-icon color="red lighten-1"
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </v-col>
                          </div>
                          <div class="caption mb-2">
                            <read-more
                              class="custom-read-more font-weight-600 font-size-16 pb-1 pr-2"
                              more-str="read more"
                              :text="row.description || ''"
                              link="#"
                              less-str="read less"
                              :max-chars="60"
                            >
                            </read-more>
                          </div>
                          <AttachmentWithName
                            v-on:file:updated="updateNoteAttachment"
                            :label="true"
                            :attachments.sync="row.attachments"
                          ></AttachmentWithName>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
                <v-col md="12" v-else>
                  <template v-if="!pageLoading">
                    <p
                      class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mx-2"
                      />
                      Uhh... There are no note for this lead at the moment.
                    </p>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" value="history">
            <InternalHistoryDetail
              type="lead"
              :type_id.sync="leadDetail.id"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <LeadDelete
        :deleteDialog.sync="leadDeleteDialog"
        :requestUrl.sync="leadDeleteRequestUrl"
        v-on:delete:success="leadDeleteSuccess"
        v-on:delete:close="leadDeleteDialog = false"
      ></LeadDelete>
      <Dialog :common-dialog="prospectDialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <div>
            <p class="mb-0 font-size-18">
              Are you sure, you want to convert this lead to prospect?
            </p>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="prospectLoading"
            :loading="prospectLoading"
            class="custom-bold-button"
            color="cyan white--text"
            v-on:click="convertToProspect()"
            >Yes! Continue</v-btn
          >
          <v-btn
            :disabled="prospectLoading"
            class="custom-bold-button"
            color="red lighten-1 white--text"
            v-on:click="prospectDialog = false"
            >No! Cancel</v-btn
          >
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH, POST, DELETE } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import Dialog from "@/view/pages/partials/Dialog";
import AttachmentView from "@/view/pages/partials/AttachmentView";
import LeadDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import Proposal from "@/view/pages/partials/Detail/Proposal.vue";
import Meeting from "@/view/pages/partials/Detail/Meeting.vue";
import Tasks from "@/view/pages/partials/Detail/Tasks";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import AttachmentWithName from "@/view/pages/partials/AttachmentWithName";
import ShowValue from "@/view/pages/partials/Show-Value.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "lead-detail",
  title: "Detail Lead",
  data() {
    return {
      lead: null,
      leadDeleteDialog: false,
      isPageLoading: false,
      moreActions: new Array(),
      attachments: new Array(),
      comments: new Array(),
      notesAttachments: new Array(),
      pageLoading: false,
      customer: null,
      prospect: null,
      attachmentLoading: false,
      attachmentActive: false,
      commentLoading: false,
      commentActive: false,
      prospectDialog: false,
      commentCreate: new Object({
        description: null,
        attachments: [],
      }),
      attachmentCreate: new Object({
        attachments: [],
      }),
      leadDetail: new Object({
        id: null,
        subject: null,
        name: null,
        company: null,
        email: null,
        phone_number: null,
        website: null,
        lead_date: null,
        last_contact: null,
        status: null,
        status_text: null,
        source: null,
        barcode: null,
        reference: null,
        unit_no: null,
        address_1: null,
        address_2: null,
        postal_code: null,
        country: null,
        category: null,
        lead_value: null,
        assigned_user: null,
        description: null,
        attachments: new Array(),
        city: null,
        state: null,
      }),
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "lead",
          disabled: false,
        },
        /*{
          title: "Meetings",
          icon: "mdi-account-multiple",
          key: "meeting",
          disabled: false,
        },*/
        /*  {
          title: "Proposals",
          icon: "mdi-account-multiple",
          key: "proposal",
          disabled: false,
        },*/
        {
          title: "Notes",
          icon: "mdi-account-multiple",
          key: "comment",
          disabled: false,
        },
        {
          title: "Attachments",
          icon: "mdi-account-multiple",
          key: "attachment",
          disabled: false,
        },
        /*{
          title: "Tasks",
          icon: "mdi-account-multiple",
          key: "task",
          disabled: false,
        },*/
        {
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
      ],
      prospectLoading: false,
    };
  },
  components: {
    Barcode,
    ShowValue,
    Dialog,
    DetailTemplate,
    Proposal,
    Meeting,
    Tasks,
    InternalHistoryDetail,
    AttachmentWithName,
    AttachmentView,
    LeadDelete,
    editor: TinyMCE,
  },
  methods: {
    convertToProspect() {
      const _this = this;
      _this.prospectLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "leads/" + _this.lead + "/prospect" })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("prospect.detail", {
              params: { id: data.prospect },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.prospectLoading = false;
        });
    },
    routeToCustomer() {
      if (this.customer) {
        this.$router.push(
          this.getDefaultRoute("customer.detail", {
            params: { id: this.customer.id },
          })
        );
      }
    },
    routeToProspect() {
      if (this.prospect) {
        this.$router.push(
          this.getDefaultRoute("prospect.detail", {
            params: { id: this.prospect.id },
          })
        );
      }
    },
    getAttachments() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead + "/attachments",
        })
        .then(({ data }) => {
          _this.attachments = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    addAttachment() {
      this.attachmentActive = true;
      this.attachmentCreate = new Object({
        attachments: [],
      });
    },
    updateAttachment(param) {
      this.attachmentCreate.attachments = param;
    },
    saveAttachment() {
      const _this = this;
      if (
        _this.attachmentCreate &&
        _this.attachmentCreate.attachments &&
        _this.attachmentCreate.attachments.length > 0
      ) {
        _this.attachmentLoading = true;
        _this.$store
          .dispatch(POST, {
            url: "leads/" + _this.lead + "/attachments",
            data: _this.attachmentCreate,
          })
          .then(() => {
            _this.attachmentCreate = new Object({
              attachments: [],
            });
            _this.attachmentActive = false;
            _this.getAttachments();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.attachmentLoading = false;
          });
      } else {
        this.attachmentActive = false;
      }
    },
    getNotes() {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead + "/comments",
        })
        .then(({ data }) => {
          _this.comments = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    addNote() {
      this.commentActive = !this.commentActive;
      this.commentCreate = new Object({
        description: null,
        attachments: [],
      });
    },
    updateNoteAttachment(param) {
      this.commentCreate.attachments = param;
    },
    saveNote() {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "leads/" + _this.lead + "/comments",
          data: _this.commentCreate,
        })
        .then(() => {
          _this.commentCreate = new Object({
            description: null,
            attachments: [],
          });
          _this.commentActive = false;
          _this.getNotes();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    deleteNote(itemid) {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "leads/" + _this.lead + "/comments/" + itemid,
        })
        .then(() => {
          _this.getNotes();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    leadDeleteSuccess() {
      this.leadDeleteDialog = false;
      this.goBack();
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("lead.update", {
              params: {
                id: _this.lead,
              },
            })
          );
          break;
        case "convert_to_customer":
          _this.$router.push(
            _this.getDefaultRoute("customer.create", {
              query: {
                lead: _this.lead,
              },
            })
          );
          break;
        case "convert_to_proposal":
          _this.$router.push(
            _this.getDefaultRoute("proposal.create", {
              query: {
                lead: _this.lead,
              },
            })
          );
          break;
        case "convert_to_prospect":
          _this.prospectDialog = true;
          break;
        case "delete":
          _this.leadDeleteDialog = true;
          break;
        case "mark_as_dead":
          _this.updateLead({ status: 3 });
          break;
        case "mark_as_attempted_to_contact":
          _this.updateLead({ status: 2 });
          break;
        case "mark_as_draft":
          _this.updateLead({ status: 1 });
          break;
        case "mark_as_junk_lead":
          _this.updateLead({ status: 4 });
          break;
      }
    },
    updateLead(data) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "leads/" + _this.lead,
          data: data,
        })
        .then(() => {
          _this.getLead();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getLead() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead,
        })
        .then(({ data }) => {
          _this.leadDetail = data;
          _this.leadDetail.attachments = new Array();
          _this.customer = data.customer;
          _this.prospect = data.prospect_relation;
          // _this.attachments = data.attachments;
          _this.moreActions = data.more_actions;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  created() {
    const _this = this;
    _this.lead = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (_this.lead <= 0) {
      _this.goBack();
    }
  },
  mounted() {
    const _this = this;
    _this.getNotes();
    _this.getAttachments();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lead", route: "lead" },
      { title: "Detail" },
    ]);

    _this.getLead();

    if (_this.$route.query && _this.$route.query.tab) {
      _this.leadTab = _this.$route.query.tab;
    }
  },
  computed: {
    leadDeleteRequestUrl() {
      return "leads/" + this.lead;
    },
    leadTitle() {
      if (this.leadDetail.title) {
        return this.leadDetail.title;
      }

      return this.leadDetail.barcode;
    },
    leadTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.leadTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "lead";
      },
    },
  },
};
</script>
